import { makeStyles } from "@material-ui/core/styles"
import { EquipmentTypeOptions, getEquipmentDisplay } from "../types"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { Typography } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({

  container: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center"
  },
  img: {
    width: "10px",
    objectFit: "contain"
  },
  imgLarge: {
    width: "10px",
    objectFit: "contain"
  },
  text: {
    textTransform: "uppercase",
    "textAlign": "right",
    marginLeft: theme.spacing(0.5)
  },
  textNoCaps: {
    textTransform: "none"
  }
}))

export interface Props {
  equipments: string[]
  type: "normal" | "large"
}

export default function EquipmentIndicator(props: Props) {
  const data = useStaticQuery(graphql`
      query {
          equipment: file(relativePath: { eq: "icon-equipment.png" }) {
              childImageSharp {
                  fluid(webpQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
          no_equipment: file(relativePath: { eq: "icon-noequipment.png" }) {
              childImageSharp {
                  fluid(webpQuality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
      }
  `)

  const classes = useStyles()
  const imageStyle = clsx({ [classes.img]: true, [classes.imgLarge]: props.type == "large" })
  const textStyle = clsx({ [classes.text]: true, [classes.textNoCaps]: props.type == "large" })

  function getEquipmentImage(equipments: string[]): FluidObject[] {
    if (equipments.length == 0 || equipments.indexOf(EquipmentTypeOptions.no) >= 0)
      return data.no_equipment.childImageSharp.fluid
    return data.equipment.childImageSharp.fluid
  }


  return <div className={classes.container}>
    <Img className={imageStyle} fluid={getEquipmentImage(props.equipments)}/>
    <Typography className={textStyle} display={"block"}
                variant={props.type == "normal" ? "h6" : "body2"}>{getEquipmentDisplay(props.equipments, "No equipment")}</Typography>
  </div>
}

